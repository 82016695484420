@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html, body, #root {
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
}

.App {
  height: 100%;
  position: relative;
}

.frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.completion-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5em;
  text-align: center;
}